<template>
    <div class="index-container">
        <div class="application-content">
            <el-scrollbar class="app-content" :native="false">
                <div class="select-wrapper">
                    <div class="select-btn" v-if="!isDelApp">
                        <el-button type="primary" @click="addAppBtn">+  应用添加</el-button>
                        <el-button type="primary" @click="manageAppBtn">应用管理</el-button>
                    </div>
                    <div class="select-btn" v-if="isDelApp">
                        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange" class="checkbox-all">全选</el-checkbox>
                        <el-button type="danger" @click="delAppBtn">删除</el-button>
                        <el-button type="info" @click="cancelDelAppBtn">取消</el-button>
                    </div>
                </div>
                <ul class="app-list-ul" v-if="applicationList.length > 0">
                    <li class="app-list-li" v-for="item in applicationList" @click="viewAppDetail(item)">
                        <div class="app-cover">
                            <div class="checkbox" v-if="isDelApp">
                                <el-checkbox-group v-model="selectAppList" @change="selectApp" class="checkbox-group">
                                    <el-checkbox :label="item.id" class="checkbox-label"></el-checkbox>
                                </el-checkbox-group>
                                <div class="edit-app-btn" @click="editAppBtn(item)">
                                    <i class="iconfont">&#xe66d;</i>
                                </div>
                            </div>
                            <img v-if="item.logo" :src="item.logo.src" alt="" class="app-icon">
                            <img v-else src="../../assets/img/image/no_cover.png" alt="" class="app-icon">
                        </div>
                        <span class="app-name">{{item.app_name}}</span>
                    </li>
                </ul>
                <div v-if="applicationList.length === 0" class="no-data">
                    <span>暂无数据</span>
                </div>
            </el-scrollbar>
            <el-pagination class="pages-right app-pages" style="margin-top: 20px;"
                           :current-page.sync="appPages.currentPageNum"
                           :page-size="appPages.eachPageNum"
                           :total="appPages.total"
                           layout="prev, pager, next, jumper"
                           @current-change="appCurrentChange">
            </el-pagination>
        </div>
        <!--添加/编辑应用弹窗-->
        <el-dialog :title="appTitleType" :visible.sync="dialogAddApp" width="650px" center class="carousel-dialog" @close="resetAppForm()" :close-on-click-modal="false">
            <el-form :model="addAppForm" ref="addAppForm" :rules="addAppRules" label-position="center" class="info-form">
                <el-form-item label="名称：" :label-width="formLabelWidth" prop="app_name">
                    <el-input v-model="addAppForm.app_name" autocomplete="off" placeholder="请输入名称"></el-input>
                </el-form-item>
                <el-form-item label="简介：" :label-width="formLabelWidth" prop="intro">
                    <el-input v-model="addAppForm.intro" autocomplete="off" placeholder="请输入简介"></el-input>
                </el-form-item>
                <el-form-item label="首页链接：" :label-width="formLabelWidth" prop="home_link">
                    <el-input v-model="addAppForm.home_link" autocomplete="off" placeholder="请输入首页链接"></el-input>
                </el-form-item>
                <el-form-item label="商家链接：" :label-width="formLabelWidth" prop="shop_link">
                    <el-input v-model="addAppForm.shop_link" autocomplete="off" placeholder="请输入商家链接"></el-input>
                </el-form-item>
                <el-form-item label="教师链接：" :label-width="formLabelWidth" prop="teacher_link">
                    <el-input v-model="addAppForm.teacher_link" autocomplete="off" placeholder="请输入教师链接"></el-input>
                </el-form-item>
                <el-form-item label="logo：" :label-width="formLabelWidth" prop="logo">
                    <div class="upload-cover">
                        <i class="iconfont">&#xe659;</i>
                        <span>{{addAppForm.logo ? '重新上传' : '上传图片'}}</span>
                    </div>
                    <input type="file" accept="image/*" title="" @change="changeLogo($event)" class="cover-input">
                    <div class="up-img-show" v-show="addAppForm.logo">
                        <img :src="addAppForm.logo" alt />
                    </div>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogAddApp = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="saveApp('addAppForm')">确 定</el-button>
            </div>
        </el-dialog>
        <!--查看应用详情弹窗-->
        <el-dialog title="欢迎使用yikeSoft" :visible.sync="dialogAppDetail" width="800px" center class="app-detail-dialog" :close-on-click-modal="false">
            <div class="app-detail-content">
                <h1 class="title">欢迎使用yikeSoft</h1>
                <div class="content">
                    <span class="h2">{{appInfo.appTitle}}</span>
                    <span class="soft-introduction">软件功能介绍：</span>
                    <div class="soft-content">{{appInfo.appContent}}</div>
                </div>
            </div>
            <div slot="footer" class="info-dialog-footer">
                <a :href="appInfo.home_link" target="_blank" class="home">首页登录</a>
                <a :href="appInfo.shop_link" target="_blank" class="shop">商家登录</a>
                <a :href="appInfo.teacher_link" target="_blank" class="shop">教师登录</a>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "applicationCenter",
        data() {
            return {
                //应用列表
                applicationList: [],
                //应用id集合
                applicationIdList: [],
                //选中的应用
                selectAppList: [],
                //弹窗标题
                appTitleType: '添加应用',
                //是否显示添加应用弹窗
                dialogAddApp: false,
                formLabelWidth: '100px',
                //添加应用表单数据
                addAppForm: {
                    id: '',
                    app_name: '',
                    intro: '',
                    home_link: '',
                    shop_link: '',
                    teacher_link: '',
                    logo: '',
                    imageFile: ''
                },
                addAppRules:{
                    app_name: [
                        { required: true, message: '请输入名称', trigger: 'blur' },
                        { min: 1, max: 18, message: '长度在 1 到 18 个字符', trigger: 'blur' }
                    ],
                    intro: [
                        { required: true, message: '请输入简介', trigger: 'blur' },
                        { min: 1, max: 500, message: '长度在 1 到 500 个字符', trigger: 'blur' }
                    ],
                    home_link: [
                        { required: true, message: '请输入首页链接', trigger: 'blur' },
                        { min: 1, max: 100, message: '长度在 1 到 100 个字符', trigger: 'blur' }
                    ],
                    shop_link: [
                        { required: true, message: '请输入商家链接', trigger: 'blur' },
                        { min: 1, max: 100, message: '长度在 1 到 100 个字符', trigger: 'blur' }
                    ],
                    teacher_link: [
                        { required: true, message: '请输入教师链接', trigger: 'blur' },
                        { min: 1, max: 100, message: '长度在 1 到 100 个字符', trigger: 'blur' }
                    ],
                },
                //分页
                appPages: {
                    currentPageNum: 1,
                    eachPageNum: 25,
                    total: 0,
                },
                //是否显示删除按钮
                isDelApp: false,
                //是否全选
                checkAll: false,
                isIndeterminate: false,
                //应用信息
                appInfo:  {
                    appTitle: '',
                    appContent: '',
                    home_link: '',
                    shop_link: '',
                    teacher_link: '',
                },
                //是否显示查看应用详情弹窗
                dialogAppDetail: false,
                distinguishBtn: 'add',
            }
        },
        mounted() {
          this.getAppList();
        },
        methods: {
            //获取应用列表
            getAppList() {
                let param = {
                    page: this.appPages.currentPageNum,
                    limit: this.appPages.eachPageNum,
                    parent_id: 0
                }
                this.$http.axiosGetBy(this.$api.app_showApp, param, (res) => {
                    if (res.code === 200) {
                        this.applicationList = res.data.data;
                        this.appPages.total = res.data.total;
                        this.applicationList.forEach(item => {
                            this.applicationIdList.push(item.id)
                        })
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //添加应用
            addAppBtn() {
                this.dialogAddApp = true;
                this.distinguishBtn = 'add';
                this.appTitleType = '添加应用';
            },
            //管理应用
            manageAppBtn() {
                this.isDelApp = true;
            },
            //上传logo
            changeLogo(event) {
                if (event.target.files.length === 0) return;
                let fileList = event.target.files[0];
                this.addAppForm.imageFile = fileList;
                let url = '';
                let reader = new FileReader();
                reader.readAsDataURL(fileList);
                let that = this;
                reader.onload = function (e) {
                    url = this.result.substring(this.result.indexOf(',') + 1);
                    that.addAppForm.logo = "data:image/png;base64," + url;
                }
                event.target.value = '';
            },
            //查看应用详情
            viewAppDetail(item) {
                if (this.isDelApp) {
                    this.dialogAppDetail = false;
                } else {
                    this.dialogAppDetail = true;
                    this.appInfo.appTitle = item.app_name;
                    this.appInfo.appContent = item.intro;
                    this.appInfo.home_link = item.home_link;
                    this.appInfo.shop_link = item.shop_link;
                    this.appInfo.teacher_link = item.teacher_link;
                }
            },
            //选择的应用
            selectApp(val) {
                let checkedCount = val.length;
                this.checkAll = checkedCount === this.applicationList.length;
                this.isIndeterminate = checkedCount > 0 && checkedCount < this.applicationList.length;
            },
            //全选
            handleCheckAllChange(val) {
                this.isIndeterminate = false;
                this.selectAppList = val ? this.applicationIdList : [];
            },
            //删除
            delAppBtn() {
                if (this.selectAppList.length === 0) {
                    this.$message.warning("请选择至少一个应用")
                    return
                }
                this.$confirm('删除后不能恢复，确定是否删除？', '删除应用', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let id = this.selectAppList.join(',')
                    this.$http.axiosGetBy(this.$api.app_delApp, {id: id}, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.msg);
                            this.getAppList();
                            this.selectAppList = [];
                        } else {
                            this.$message.error(res.msg);
                        }
                    }, (err) => {
                        console.log(err);
                    })
                }).catch(() => {
                    this.$message.info('已取消删除')
                });
            },
            //取消删除
            cancelDelAppBtn() {
                this.isDelApp = false;
                this.selectAppList = [];
            },
            //编辑
            editAppBtn(item) {
                this.dialogAddApp = true;
                this.distinguishBtn = 'edit';
                this.appTitleType = '编辑应用';
                this.addAppForm.id = item.id;
                this.addAppForm.app_name = item.app_name;
                this.addAppForm.intro = item.intro;
                this.addAppForm.home_link = item.home_link;
                this.addAppForm.shop_link = item.shop_link;
                this.addAppForm.teacher_link = item.teacher_link;
                this.addAppForm.logo = item.logo.src;
            },
            //保存
            saveApp(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let strRegex= '(https?|ftp|file)://[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]';
                        let re = new RegExp(strRegex)
                        if (!re.test(this.addAppForm.home_link)) {
                            this.$message.warning('首页链接不是以http://https://开头，或者不是网址！');
                            return;
                        }
                        if (!re.test(this.addAppForm.shop_link)) {
                            this.$message.warning('商家链接不是以http://https://开头，或者不是网址！');
                            return;
                        }
                        if (!re.test(this.addAppForm.teacher_link)) {
                            this.$message.warning('教师链接不是以http://https://开头，或者不是网址！');
                            return;
                        }
                        if (this.distinguishBtn === 'add') {
                            let formData = new FormData();
                            formData.append('app_name', this.addAppForm.app_name);
                            formData.append('intro', this.addAppForm.intro);
                            formData.append('home_link', this.addAppForm.home_link);
                            formData.append('shop_link', this.addAppForm.shop_link);
                            formData.append('teacher_link', this.addAppForm.teacher_link);
                            if (this.addAppForm.imageFile) {
                                formData.append('logo', this.addAppForm.imageFile)
                            }
                            this.$http.axiosPost(this.$api.app_saveApp, formData, (res) => {
                                if (res.code === 200) {
                                    this.$message.success(res.msg);
                                    this.getAppList();
                                    this.dialogAddApp = false;
                                    this.resetAppForm();
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            }, (err) => {
                                console.log(err)
                            })
                        } else if (this.distinguishBtn === 'edit') {
                            let formData = new FormData();
                            formData.append('id', this.addAppForm.id);
                            formData.append('app_name', this.addAppForm.app_name);
                            formData.append('intro', this.addAppForm.intro);
                            formData.append('home_link', this.addAppForm.home_link);
                            formData.append('shop_link', this.addAppForm.shop_link);
                            formData.append('teacher_link', this.addAppForm.teacher_link);
                            if (this.addAppForm.imageFile) {
                                formData.append('logo', this.addAppForm.imageFile)
                            }
                            this.$http.axiosPost(this.$api.app_saveApp, formData, (res) => {
                                if (res.code === 200) {
                                    this.$message.success(res.msg);
                                    this.getAppList();
                                    this.dialogAddApp = false;
                                    this.resetAppForm();
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            }, (err) => {
                                console.log(err)
                            })
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            //重置表单
            resetAppForm() {
                this.addAppForm = {
                    id: '',
                    app_name: '',
                    intro: '',
                    home_link: '',
                    shop_link: '',
                    teacher_link: '',
                    logo: '',
                    imageFile: ''
                }
            },
            //分页
            appCurrentChange(val) {
                this.appPages.currentPageNum = val;
                this.getAppList();
            }
        }
    }
</script>

<style scoped lang="scss">
    .application-content {
        padding: 20px 0;
        height: calc(100% - 40px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .app-content {
            height: 100%;
            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }
        }
        .select-wrapper {
            padding: 0 20px;
            .select-btn {
                display: flex;
                justify-content: flex-end;
                .checkbox-all {
                    margin-right: 20px;
                }
            }
        }
        .app-list-ul {
            padding: 0 20px;
            display: flex;
            flex-wrap: wrap;
            li.app-list-li {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 10px 16px 20px 0;
                cursor: pointer;
                border-radius: 10px;
                &:hover {
                    transform: scale(1.2);
                    transition: 1s;
                }
                .app-cover {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 70px;
                    height: 70px;
                    .checkbox {
                        position: absolute;
                        top: 0;
                        right: 0;
                        height: 100%;
                        .checkbox-group {
                            .checkbox-label {
                               ::v-deep .el-checkbox__label {
                                    display: none;
                                }
                            }
                        }
                        .edit-app-btn {
                            position: absolute;
                            bottom: 0;
                            background-color: #DCDFE6;
                            border-radius: 50%;
                            height: 16px;
                            width: 16px;
                            text-align: center;
                            line-height: 16px;
                            i {
                                font-size: 12px;
                                color: #979aa0;
                            }
                            &:hover {
                                background-color: #409EFF;
                                i {
                                    font-size: 12px;
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
                img.app-icon {
                    max-width: 100%;
                    max-height: 100%;
                    border-radius: 5px;
                }
                span.app-name {
                    color: #333;
                    font-size: 12px;
                    margin-top: 10px;
                    display: block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 216px;
                    text-align: center;
                }
            }
        }
        .no-data {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            margin-top: 50px;
        }
    }
    .app-pages {
        padding: 0 20px;
    }
    .upload-cover {
        position: relative;
        background-color: #DCECFE;
        border-color: #DCECFE;
        padding: 0;
        width: 80px;
        height: 80px;
        color: #409EFF;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        font-size: 12px;
        border-radius: 10px;
        i {
            width: 16px;
            height: 16px;
            margin: 5px;
        }
    }
    .cover-input {
        position: absolute;
        width: 80px;
        height: 80px;
        top: 0;
        opacity: 0;
        cursor: pointer;
    }
    .up-img-show {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 80px;
        height: 80px;
        top: 0;
        left: 100px;
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
    .app-detail-dialog {
        ::v-deep .el-dialog {
            ::v-deep .el-dialog__header {
                border-bottom: 1px solid #e6e7ea;
            }
            .title {
                text-align: center;
                margin: 0;
            }
            .content {
                display: flex;
                flex-direction: column;
                .h2 {
                    font-size: 18px;
                    font-weight: 500;
                    text-align: center;
                    margin: 10px 0;
                }
                .soft-introduction {
                    text-align: center;
                }
                .soft-content {
                    padding: 10px 50px;
                    min-height: 200px;
                }
            }
            ::v-deep .el-dialog__footer {
                border-top: 1px solid #e6e7ea;
                padding: 20px;
                a {
                    padding: 7px 20px;
                    font-size: 14px;
                    border-radius: 4px;
                    margin-right: 20px;
                }
                a.home {
                    border: 1px solid #DCDFE6;
                    color: #606266;
                    &:hover {
                        color: #409EFF;
                        border-color: #c6e2ff;
                        background-color: #ecf5ff;
                    }
                }
                a.shop {
                    color: #FFF;
                    background-color: #409EFF;
                    border-color: #409EFF;
                    &:hover {
                        background: #66b1ff;
                        border-color: #66b1ff;
                        color: #FFF;
                    }
                }
            }
        }
    }
</style>